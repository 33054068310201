.footer {
  $this: &;
  @include mob {
    @include port {
      margin-bottom: 200px; }
    @include land {
      margin-bottom: 120px; }
    @include sm {
      @include land {
        margin-bottom: 170px; } } }
  &__top {
    background: $black2;
    padding-top: 64px; }
  &__top-menu {
    padding: 29px 32px 35px;
    background: $main-blue;
    @include md {
      padding: 40px; }
    @include sm {
      padding: 30px 20px; }
    @include xs {
      padding: 15px 20px; }
    li {
      display: inline-block;
      vertical-align: top;
      margin: 10px 0;
      @include xs {
        margin: 7px 0; }
      &:not(:last-of-type) {
        margin-right: 15px; } }
    a {
      font-size: 22px;
      @include medium;
      color: $white;
      text-transform: uppercase;
      transition: color $anim;
      @include hover {
        color: $yellow; }
      @include lg {
        font-size: 16px; }
      @include xs {
        font-size: 14px; } } }
  &__bottom-inner {
    padding: 47px 100px 50px;
    background: $black2;
    @include md {
      padding: 30px 40px 85px; }
    @include mob {
      @include sm {
        padding: 30px 20px 35px; } } }
  &__menu {
    margin-bottom: 10px;
    @include sm {
      margin-bottom: 30px; }
    li {
      font-size: 0;
      &:not(:last-of-type) {
        margin-bottom: 12px; } }
    a {
      font-size: 22px;
      color: $gray;
      transition: color $anim;
      @include hover {
        color: $white; }
      @include sm {
        font-size: 14px; } } }
  &__logos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-left, &-right {
      display: flex;
      align-items: center; } }
  &__logo-wrapper {
    div {
      width: 100%;
      max-width: 100%;
      height: 100%; }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      @include sm {
        height: auto;
        max-width: 100%;
        width: auto; } }
    &--18 {
      margin-left: 43px;
      @include sm {
        margin-left: 12px; }
      svg {
        width: 68px;
        height: 68px;
        fill: $white;
        @include sm {
          width: 40px;
          height: 40px; } } }
    &--circle {
      position: relative;
      width: 60px;
      height: 60px;
      margin-left: 12px;
      overflow: hidden;
      border-radius: 50%;
      &::before {
        content: '';
        @include coverdiv;
        background: rgba(#585857,0.8); }
      @include sm {
        width: 40px;
        height: 40px; } } } }

