@mixin black {
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: 900;
  font-style: normal; }
@mixin ebold {
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: 800;
  font-style: normal; }
@mixin bold {
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: 700;
  font-style: normal; }
@mixin semibold {
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: 600;
  font-style: normal; }
@mixin medium {
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: 500;
  font-style: normal; }
@mixin reg {
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: 400;
  font-style: normal; }
