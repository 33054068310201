@mixin burger-menu-styles {
  display: none;
  position: absolute;
  z-index: 100;
  left: 0px;
  top: calc(100% + 11px);
  min-width: 266px;
  padding: 22px 19px 25px;
  background: $blue;
  border-radius: 3px;
  a {
    display: block;
    line-height: 1.5;
    font-size: 17px;
    @include black;
    color: $white;
    transition: color $anim;
    @include hover {
      color: $yellow; } }
  li {
    &:not(:last-of-type) {
      margin-bottom: 12px; } } }
.header {
  $this: &;
  position: relative;
  z-index: 100;
  width: 100%;
  @include mob {
    height: 180px;
    background: $main-blue;
    @include sm {
      height: auto; } }
  &__inner {}

  &__top-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $main-blue;
    @include mob {
      display: block;
      position: static; } }
  &__bottom-inner {
    z-index: 1;
    display: flex;
    align-items: center;
    padding: 11px 0 11px 69px;
    min-height: 58px;
    background: $main-blue;
    box-shadow: $shadow;
    @include md {
      padding-left: 15px; }
    @include mob {
      padding: 0;
      min-height: 0; } }
  &__nav-side {}
  &__logo-side {
    padding-left: 68px;
    @include md {
      padding-left: 15px; }
    @include mob {
      display: flex;
      justify-content: center;
      width: 100%;
      padding-left: 0; } }
  &__btns-side {
    font-size: 0;
    text-align: right;
    padding-right: 25px;
    @include mob {
      padding: 0; } }
  &__btns-wrap {
    display: flex;
    align-items: center;
    max-width: 450px;
    flex-wrap: wrap;
    justify-content: flex-end; }
  &__btn {
    &--spec {
      display: inline-flex;
      align-items: center;
      margin-right: 20px;
      @include black;
      font-size: 16px;
      line-height: 1.5;
      color: $green;
      text-shadow: 0px 0px 4px #2BBB29;
      transition: opacity $anim;
      @include hover {
        opacity: 0.8; }
      svg {
        width: 18px;
        height: 24px;
        fill: $green;
        margin-right: 12px; } }
    &--hidden-mob {
      @include mob {
        display: none; }
      @include sm {
        display: none; } }
    &-mob {
      display: none;
      position: absolute;
      right: 50px;
      top: 90px;
      transform: translate(0, -50%);
      font-size: 0;
      svg {
        width: 40px;
        height: 40px;
        fill: $white; }
      @include mob {
        display: block;
        @include sm {
          right: 15px;
          top: 50px; } } }
    &.btn--mob-orange {
      margin-top: 15px; } }
  &__burger-wrapper {
    position: relative;
    @include mob {
      position: absolute;
      left: 50px;
      top: 90px;
      transform: translate(0, -50%);
      @include sm {
        left: 15px;
        top: 50px; } } }
  &.is-only-burger {
    #{$this}__menu {
      @include burger-menu-styles; } }
  &.is-mob-btns {
    #{$this}__btns-wrap {
      @include mob {
        justify-content: center;
        max-width: 100%;
        padding: 25px 0;
        box-shadow: $shadow;
        @include xs {
          padding: 15px 0; } } }
    #{$this}__btn {
      @include mob {
        &.btn--mob-orange, &.btn--mob-border {
          @include mob {
            display: inline-block;
            margin-top: 0; } }
        &.btn--mob-orange {
          margin-right: 30px;
          order: 1; }
        &.btn--mob-border {
          order: 2; } } } }
  &:not(.is-only-burger) {
    #{$this}__bottom-inner {
      @include lg {
        padding-left: 20px; } }
    #{$this}__menu {
      @include rmin(1025) {
        display: block!important;
        ul {
          display: flex; }
        li {
          &:not(:last-of-type) {
            margin-right: 28px;
            @include lg {
              margin-right: 15px; } } }
        a {
          font-size: 17px;
          line-height: 1.5;
          @include black;
          color: $white;
          transition: color $anim;
          text-transform: uppercase;
          @include lg {
            font-size: 16px; }
          @include hover {
            color: $yellow; }
          // +lg
 } }          //   font-size: 18px
      @include r(1024) {
        @include burger-menu-styles; } }
    .burger {
      @include rmin(1025) {
        display: none; } } } }
.header-logo {
  display: block;
  font-size: 0;
  &__img {
    width: 250px;
    height: 151px;
    @include sm {
      width: 200px;
      height: 100px; } } }

.burger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 26px;
  cursor: pointer;
  &__inner {
    position: relative;
    width: 100%;
    height: 100%; }
  span {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background: $white;
    transition: transform $anim, opacity $anim, width $anim;
    border-radius: 1px;
    &:nth-of-type(1) {
      transform-origin: 0 0;
      top: 0; }
    &:nth-of-type(2) {
      top: 50%;
      margin-top: -2px; }
    &:nth-of-type(3) {
      bottom: 0;
      transform-origin: 0% 100%; } }
  &.is-active {
    span {
      &:nth-of-type(1) {
        transform: rotate(34deg);
        width: 120%;
        // +mob
        //   width: 136%
 }        //   transform: rotate(38deg)
      &:nth-of-type(2) {
        opacity: 0; }
      &:nth-of-type(3) {
        transform: rotate(-34deg);
        width: 120%;
        // +mob
        //   width: 136%
 } } } }        //   transform: rotate(-38deg)


