$white: #fff;
$black: #000;
$black2: #000407;
$black3: #020202;


$main-red: #b20427;
$red: #931629;
$red1: #6a2331;
$red2: #601e2b;
$red3: #c62f42;
$red4: #C82C3F;
$red5: #B20426;
$orange: #f8f3d5;
$orange1: #ff5c2f;
$orange2: #e98e45;
$orange3: #F8A31A;
$yellow: #f5df83;
$yellow2: #ffff97;
$yellow3: #f2f72b;
$yellow4: #8c8c17;
$yellow5: #FCEC6F;
$blue: #4661b8;
$blue2: #658cff;
$blue3: #5a7cea;
$green: #3bb224;
$green2: #72e25c;
$grey: #4a4a4b;



$main-blue: #011422;
$blue: #1E3444;
$blue2: #0A131F;
$orange: #FAC41D;
$orange2: #F57915;
$orange3: #E06D03;
$orange4: #F57916;
$gray: #9B9B9B;
$gray2: #F2F2F2;
$gray3: #454545;
$gray4: #2B2929;
$green: #44D73F;
$green2: #97EF46;
$green3: #076F18;

$anim: 0.25s ease-in;
$shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 9px rgba(105, 105, 105, 0.22);
$dshadow: 0px -1px 81px rgba(0, 0, 0, 0.71);
