.bonus-box {
  position: relative;
  $this: &;
  height: 100%;
  padding-bottom: 40px;
  @include sm {
    display: block; }
  &__inner {
    width: 100%;
    flex-grow: 0; }
  &__img {
    display: block;
    position: relative;
    height: 0;
    font-size: 0;
    padding-bottom: 99%;
    overflow: hidden;
    box-shadow: 0 0 93px 0 #640193;
    &::after {
      content: '';
      @include coverdiv;
      z-index: 1;
      opacity: 0;
      background: rgba($black, 0.1);
      transition: opacity $anim; }
    img {
      @include coverdiv;
      z-index: 0;
      object-fit: cover;
      z-index: 0; }
    @include hover {
      &::after {
        opacity: 1; }
      #{$this}__img-btn {
        opacity: 1; } }
    @include is-touch {
      &.is-active {
        &::after {
          opacity: 1; }
        #{$this}__img-btn {
          opacity: 1; } } } }
  &__img-btn {
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 30px;
    width: 100%;
    text-align: center;
    transition: opacity $anim;
    opacity: 0; }
  &__main-text, &__text {
    color: $white;
    text-align: center; }
  &__main-text {
    flex-grow: 0;
    width: 100%;
    margin: 25px 0 27px;
    @include semibold;
    font-size: 28px;
    text-transform: uppercase;
    @include lg {
      font-size: 22px; }
    @include md {
      font-size: 18px; } }
  &__text-wrapper {
    padding: 0 10px;
    width: 100%; }
  &__text {
    margin-bottom: 20px;
    padding: 0 10px;
    line-height: 1.5;
    font-size: 18px;
    letter-spacing: 0.25px;
    @include reg;
    @include md {
      font-size: 16px; }
    @include xs {
      font-size: 14px; } }

  &__bottom-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    text-align: center; } }
