.bonus-plate {
  padding-bottom: 40px;
  &__top-btn {
    margin-bottom: 75px;
    text-align: center; }
  &__wrapper {
    padding: 0 100px;
    @include lg {
      padding: 0 30px; } }
  &__inner {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -27px;
    @include md {
      margin: 0 -15px; }
    @include sm {
      display: block;
      text-align: center; } }
  &__item {
    margin-bottom: 70px;
    width: 33.33%;
    padding: 0 27px;
    flex-grow: 0;
    @include md {
      padding: 0 15px; }
    @include sm {
      width: 100%; } } }
