.banner-block {
  $this: &;
  &__inner {
    position: relative; }
  &__btn-wrapper {
    width: 100%;
    margin: 0 auto; }
  &__btn {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    overflow: hidden;
    font-size: 0;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; } }
  &__rating {
    padding-top: 40px;
    @include sm {
      padding-top: 10px; } }
  &--1206 {
    padding: 160px 0 0;
    @include md {
      padding-top: 100px; }
    @include mob {
      padding-top: 75px; }
    #{$this}__btn-wrapper {
      max-width: 1206px; }
    #{$this}__btn {
      padding-bottom: 57.6%;
      border-radius: 20px;
      overflow: hidden;
      @include md {
        border-radius: 0; } } }
  &--744 {
    padding: 120px 0 30px;
    @include md {
      padding: 100px 0 0px; }
    @include xs {
      padding-top: 70px; }
    #{$this}__btn-wrapper {
      max-width: 744px; }
    #{$this}__btn {
      padding-bottom: 30.4%;
      border: 3px solid $yellow; } } }

