.btn {
  $this: &;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  min-width: 160px;
  padding: 0px 10px;
  font-size: 16px;
  @include black;
  color: $white;
  appearance: none;
  border: none;
  background: linear-gradient(180deg, $orange 0%, $orange2 100%);
  border-radius: 3px;
  transition: opacity $anim;
  box-sizing: border-box;
  text-transform: uppercase;
  @include sm {
    height: 36px;
    padding: 0 9px;
    font-size: 16px;
    @include medium; }
  @include hover {
    opacity: 0.6; }
  &--orange-dark {
    font-size: 22px;
    height: 40px;
    @include medium;
    color: $gray4;
    text-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25);
    @include lg {
      min-width: 1px;
      height: 36px;
      font-size: 18px;
      padding: 0 20px; } }
  &--orange-big {
    height: 74px;
    padding: 0 15px;
    font-size: 49px;
    border-radius: 5px;
    @include md {
      height: 60px;
      padding: 0 20px;
      font-size: 24px; } }
  &--green {
    background: linear-gradient(180deg, $green2 0%, $green3 100%); }
  &--green-big {
    height: 84px;
    padding: 0 24px;
    background: linear-gradient(180deg, $green2 0%, $green3 100%);
    font-size: 39px;
    @include md {
      height: 60px;
      padding: 0 12px;
      font-size: 24px; } }


  &--border {
    @include medium;
    color: $yellow5;
    padding: 0 13px;
    font-size: 15px;
    border: 1px solid $yellow5; }
  &--mob-orange {
    @include mob {
      height: 60px;
      min-width: 1px;
      padding: 0 18px;
      background: linear-gradient(180deg, $orange 0%, $orange2 100%);
      font-size: 24px;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      color: rgba($white, 0.82);
      @include xs {
        font-size: 14px;
        height: 40px; } } }
  &--mob-border {
    @include mob {
      height: 60px;
      min-width: 1px;
      padding: 0 72px;
      background: transparent;
      font-size: 24px;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      color: $orange3;
      border: 4px solid $orange3;
      @include xs {
        font-size: 14px;
        padding: 0 18px;
        height: 40px;
        border: 3px solid $orange3; } } } }



