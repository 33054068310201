.breadcrumbs {
  &__inner {
    padding-left: 69px;
    padding-bottom: 15px;
    @include lg {
      padding: 0 15px 20px;
      text-align: center; } }
  li {
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding: 17px 0 15px;
    &:not(:last-child) {
      &::after {
        content: '-';
        font-size: 18px;
        color: rgba($white, 0.7);
        @include reg;
        @include sm {
          font-size: 12px; } } } }
  a {
    font-size: 18px;
    color: rgba($white, 0.7);
    @include reg;
    transition: color $anim;
    @include sm {
      font-size: 12px; }
    @include hover {
      color: $yellow; } } }
