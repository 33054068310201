.container {
  max-width: 1500px;
  padding: 0 30px;
  margin: 0 auto;
  @include md {
    padding: 0 20px; }
  &--md-p-0 {
    @include md {
      padding: 0; } } }

