.rating {
  display: flex;
  justify-content: center;
  &__btn {
    font-size: 0;
    line-height: 1;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 15px; }
    svg {
      width: 100px;
      height: 100px;
      fill: $orange;
      transition: fill $anim;
      @include mob {
        width: 12vw;
        height: 12vw;
        @include port {
          width: 13vw;
          height: 13vw; } } }
    &.is-active, &.is-hover {
      svg {
        fill: $orange4; } } } }


