.hero {
  $this: &;
  &__inner {
    display: flex;
    padding-top: 20px;
    @include md {
      display: block; } }
  &__img {
    width: 874px;
    max-height: 446px;
    @include md {
      width: 100%;
      max-height: none;
      border: 6px solid $orange3; }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  &__tables {
    width: 626px;
    padding-left: 22px;
    @include md {
      width: 100%;
      padding-left: 0; } }
  &__table-wrapper {
    @include mob {
      width: 100%;
      overflow: scroll;
      .hero-table {
        min-width: 350px;
        margin-bottom: 10px; } } }
  &__bottom-text {
    padding: 15px 45px 8px;
    font-size: 38px;
    line-height: 1.5;
    background: $black2;
    color: $green;
    text-shadow: 0px 0px 4px #2BBB29;
    @include md {
      padding-left: 32px; }
    @include sm {
      font-size: 20px; }
    strong {
      position: relative;
      display: inline-block;
      vertical-align: top;
      font-weight: normal;
      @include black; }
    i {
      @include vcenter;
      font-style: normal;
      font-size: 70px;
      opacity: 0.2;
      @include sm {
        font-size: 40px; } } } }

.hero-top-table, .hero-bottom-table {
  background: $main-blue;
  box-shadow: $shadow;
  padding: 0 10px 20px;
  &__title {
    margin-bottom: 4px;
    font-size: 38px;
    line-height: 1.5;
    @include black;
    text-align: center; } }
.hero-top-table {
  margin-bottom: 22px;
  &__title {
    color: $orange;
    text-shadow: 0px 0px 4px #2BBB29; } }
.hero-bottom-table {
  &__title {
    color: $green;
    text-shadow: 0px 0px 4px #2BBB29; } }
.hero-table {
  table-layout: fixed;
  @include md {
    table-layout: auto; }
  tr {
    &:not(:last-of-type) {
      td {
        border-bottom: 4px solid #011522; } } }
  &__cell {
    vertical-align: middle;
    height: 40px;
    &--btns {
      width: 152px; }
    &--currency, &--value, &--name {
      white-space: nowrap;
      text-overflow: ellipsis; }
    &--currency, &--value {
      padding: 0 15px;
      background: $black2;
      font-size: 25px;
      @include l {
        font-size: 20px; } }
    &--currency {
      width: 100px;
      text-align: left;
      @include medium;
      color: rgba($white, 0.42);
      @include l {
        width: 80px; } }
    &--value {
      text-align: right;
      @include medium;
      color: rgba($white, 0.97); }
    &--name {
      padding: 0 15px;
      font-size: 18px;
      color: rgba($white, 0.42);
      background: $black2; }
    &--icon {
      width: 42px;
      height: 30px;
      background: $black2;
      text-align: center; } }
  &__icon {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    background: $gray4;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } } }
.pseudo-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 152px;
  height: 40px;
  padding: 3px 20px 4px;
  border-radius: 3px;
  font-size: 22px;
  color: $gray3;
  text-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25);
  &--red {
    background: linear-gradient(180deg, #FF747F 0%, #DD0618 100%); }
  &--orange {
    background: linear-gradient(180deg, #FAC41D 0%, #F57915 100%); }
  &--gray {
    background: linear-gradient(180deg, #DEDEDE 0%, #6F6F6F 100%); } }
