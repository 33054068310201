.btns-hero {
  margin-bottom: 110px;
  @include md {
    margin-bottom: 80px; }
  $this: &;
  &__top-btn {
    display: flex;
    justify-content: center;
    margin-bottom: 34px;
    @include md {
      margin-bottom: 50px; } }
  &__inner {
    position: relative; }
  &__img-wrapper {
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: 0;
    padding-bottom: 57.5%;
    overflow: hidden;
    font-size: 0;
    border: 3px solid $yellow;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0; } }
  &__img-btn {
    width: 100%;
    text-align: center;
    position: absolute;
    z-index: 1;
    bottom: 45px;
    left: 0;
    @include md {
      bottom: 10px; } } }
