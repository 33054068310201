.game-plate {
  &__wrapper {
    padding: 40px 40px 150px;
    background: $main-blue;
    box-shadow: $shadow;
    @include md {
      padding: 40px 30px 150px; }
    @include mob {
      padding: 40px 20px 150px; } }
  &__inner {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
    @include mob {
      margin: 0 -10px; } }
  &__item {
    width: calc(100% / 6);
    padding: 0 12px;
    margin-bottom: 30px;
    @include lg {
      width: calc(100% / 5); }
    @include md {
      width: calc(100% / 4);
      margin-bottom: 15px; }
    @include mob {
      width: calc(100% / 3);
      padding: 0 10px;
      @include port {
        width: calc(100% / 2); } } }
  &__bottom-text {
    padding: 15px 45px 8px;
    font-size: 38px;
    line-height: 1.5;
    color: $green;
    text-shadow: 0px 0px 4px #2BBB29;
    @include md {
      padding-left: 32px; }
    @include sm {
      font-size: 20px; }
    strong {
      position: relative;
      font-weight: normal;
      @include black; } } }
