body {
	background-color: $blue2;
	@include font-smoothing;
	font-size: 16px;
	line-height: 1.2;
	@include reg; }

.out {
	position: relative;
	background-repeat: repeat; }

.main-container {
	max-width: 1500px;
	padding: 0 30px;
	margin: 0 auto;
	@include md {
		padding: 0; }
	&__inner {
		background: $black2;
		display: flex;
		flex-wrap: wrap;
		box-shadow: $dshadow; } }

.section {
	width: 100%; }

.order-1 {
	order: 1; }
.order-2 {
	order: 2; }
.order-3 {
	order: 3; }
.order-4 {
	order: 4; }
.order-5 {
	order: 5; }
.order-6 {
	order: 6; }
.order-7 {
	order: 7; }
.order-8 {
	order: 8; }
.order-9 {
	order: 9; }
.order-10 {
	order: 10; }

button {
	appearance: none;
	border: none;
	background: transparent; }

a {
	text-decoration: none; }

h1, h2, h3, h4, h5, h6 {
	font-weight: normal; }
