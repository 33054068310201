.game-box {
  $this: &;
  position: relative;

  &__main {
    position: relative;
    height: 0;
    padding-bottom: 100%;
    overflow: hidden;
    font-size: 0;
    @include hover {
      #{$this}__content {
        opacity: 1; } }
    @include is-touch {
      &.is-active {
        #{$this}__content {
          opacity: 1;
          pointer-events: all; }
        & + #{$this}__title {
          opacity: 1; } } } }
  &__bg, &__content {
    @include coverdiv; }
  &__bg {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }
  &__content {
    z-index: 1;
    background: rgba($black, 0.6);
    transition: opacity $anim;
    height: 100%;
    opacity: 0;
    @include is-touch {
      pointer-events: none; }
    @include md {
      padding-top: 0;
      display: flex;
      justify-content: center;
      align-items: center; } }
  &__btn-wrap {
    display: flex;
    justify-content: center;
    @include vcenter;
    top: 52%; }
  &__title {
    width: 100%;
    padding: 0px 5px 0;
    text-align: center;
    a {
      font-size: 23px;
      line-height: 1.5;
      color: rgba($white, 0.7);
      transition: color $anim;
      @include lg {
        font-size: 18px; }
      @include md {
        font-size: 16px; }
      @include hover {
        color: $yellow; } } } }


