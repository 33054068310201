.scroll-panel {
  display: none;
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  width: 100%;
  background: $main-blue;
  transform: translateY(102%);
  transition: transform $anim;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  box-shadow: $shadow;
  @include mob {
    display: block; }
  &.is-active {
    transform: translateY(0); }
  &__top {
    position: relative; }
  &__logo {
    position: absolute;
    left: 30px;
    top: 5px;
    width: 80px;
    @include xs {
      left: 20px; }
    img {
      width: 100%;
      height: auto; } }
  &__close-btn {
    position: absolute;
    top: 15px;
    right: 20px;
    z-index: 1;
    svg {
      width: 30px;
      height: 30px;
      fill: #DCDBDB; } }
  &__text {
    padding: 50px 30px 10px;
    font-size: 18px;
    @include medium;
    color: $white;
    @include xs {
      padding: 50px 20px 10px; } }
  &__btn {
    background: linear-gradient(180deg, #DCA600 0%, #F57915 100%);
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: $white;
    @include ebold;
    font-size: 30px;
    text-transform: uppercase; } }
