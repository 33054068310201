.icon-18 {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-close {
  width: 1.06em;
  height: 1em;
  fill: none;
}
.icon-desert-clock {
  width: 0.79em;
  height: 1em;
  fill: #44d73f;
}
.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: initial;
}
.icon-logout {
  width: 1em;
  height: 1em;
  fill: #fff;
}
.icon-play {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-star {
  width: 1.08em;
  height: 1em;
  fill: initial;
}
