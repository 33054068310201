.article-block {
  &__inner {
    padding: 30px 35px 50px;
    background: $main-blue;
    box-shadow: $shadow;
    font-size: 20px;
    color: $white;
    @include reg;
    @include mob {
      padding: 40px 20px;
      font-size: 14px; }
    h1, h2, h3 {
      margin: 24px 0;
      @include ebold;
      @include sm {
        margin-bottom: 10px 0; } }
    h1 {
      font-size: 45px;
      margin: 30px 0;
      @include sm {
        font-size: 18px;
        margin: 12px 0; } }
    h2 {
      font-size: 36px;
      @include sm {
        font-size: 16px; } }
    h3 {
      font-size: 30px;
      @include sm {
        font-size: 14px; } }
    p {
      text-indent: 26px;
      margin-bottom: 20px;
      @include sm {
        margin-bottom: 10px; } }
    ul, ol {
      margin-top: 50px;
      margin-bottom: 50px;
      list-style-type: none;
      @include sm {
        margin: 30px 0; } }
    ul {
      li {
        &::before {
          content: '-';
          margin-right: 8px; } } }
    ol {
      @include counter(0, ' '); } }
  a {
    color: $orange;
    transition: color $anim;
    @include hover {
      color: $orange3; } }
  img {
    display: block;
    max-width: 800px;
    width: 100%;
    height: auto;
    margin: 40px auto; }

  table {
    border-collapse: separate;
    border-spacing: 0px 30px;
    table-layout: fixed;
    @include md {
      table-layout: auto; }
    tr {
      td {
        @include reg;
        &:nth-of-type(1) {
          width: 46%;
          @include bold;
          @include md {
            width: calc(50% - 10px); } }
        &:nth-of-type(2) {
          width: 8%;
          border: none;
          @include md {
            width: 20px; } }
        &:nth-of-type(3) {
          width: 54%;
          @include md {
            width: calc(50% - 10px); } } } }
    td {
      border-bottom: 1px solid $white;
      font-size: 18px;
      @include bold;
      color: $white;
      @include md {
        font-size: 14px;
        @include reg; } } } }
