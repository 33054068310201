.game-block {
  $this: &;
  position: relative;
  margin-bottom: 92px;
  @include md {
    margin-bottom: 75px; }
  &__main {
    position: relative;
    z-index: 1; }
  &__inner {
    @include md {
      display: flex;
      flex-wrap: wrap; } }
  &__btn-outside {
    margin-bottom: 75px;
    text-align: center;
    @include md {
      width: 100%;
      margin-bottom: 50px; } }
  &__game-wrapper {
    width: 100%;
    @include md {
      flex-grow: 1; } }
  &__game-inner {
    position: relative;
    height: 0;
    padding-bottom: 57.6%;
    overflow: hidden;
    img {
      @include coverdiv;
      z-index: 0;
      transition: opacity $anim;
      pointer-events: none; }
    iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1; } }
  &__btn {
    width: 140px;
    height: 140px;
    @include vcenter;
    z-index: 1;
    cursor: pointer;
    transition: opacity $anim;
    border-radius: 50%;
    overflow: hidden;
    background: $black3;
    font-size: 0;
    @include hover {
      svg {
        fill: $orange3; } }
    @include md {
      width: 120px;
      height: 120px; }
    @include mob {
      width: auto;
      height: auto; }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
      fill: $orange;
      transition: fill $anim;
      @include mob {
        width: 24vw;
        height: 24vw; } } }

  &.is-active {
    #{$this}__preview, #{$this}__btn {
      opacity: 0; }
    #{$this}__btn {
      pointer-events: none; } } }

